/* src/pages/Login.css */

@import '../styles/variables.css'; /* Import color variables */

/* ----- General Layout ----- */
.login-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--navbar-height);
}

/* ----- Containers (Login, Ko-fi, Password) ----- */
.login-page .login-container,
.login-page .kofi-container,
.login-page .password-container {
  margin-top: 20px;
  padding: 20px;
  border: 2px solid var(--color4);
  background-color: var(--color4transparent);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  max-width: 500px;
  width: 100%;
  text-align: left;
}

/* ----- Titles & Text ----- */
.login-page h1,
.login-page h2 {
  color: var(--color1);
  margin-bottom: 15px;
}

.login-page p {
  color: var(--color5);
  margin-bottom: 10px;
  line-height: 1.6;
}

.login-page .linked-emails li {
  color: var(--color5);
  font-weight: bold;
}

/* ---------------- BUTTONS (Modern Design) ---------------- */

/* General Button Style */
.login-page .login-button,
.login-page .auth-button,
.login-page .logout-button,
.login-page .admin-page-button,
.login-page .toggle-admin-button,
.login-page .patreon-login-button,
.login-page .link-email-form button,
.login-page .unverified-panel button,
.login-page .kofi-message-buttons button,
.login-page .password-container button,
.login-page .button-group button {
  padding: 12px 20px;
  border: 2px solid var(--color4);
  background-color: var(--color4transparent);
  color: var(--color1);
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 300px;
}

/* Hover effect */
.login-page button:hover {
  background-color: var(--color4);
  transform: scale(1.05);
}

/* Disable default button focus */
.login-page button:focus {
  outline: none;
}

/* Buttons inside groups should be uniform */
.login-page .button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Login Buttons (Patreon, Google) */
.login-page .login-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.login-page .login-button img {
  width: 22px;
  height: 22px;
}

/* Authentication Buttons */
.login-page .auth-button {
  max-width: 250px;
}

/* Centered Patreon Login in Signup Tab */
.login-page .centered-button {
  display: flex;
  justify-content: center;
}

/* Logout Button */
.login-page .logout-button {
  background-color: var(--color4transparent);
  border: 2px solid var(--color4);
  color: var(--color1);
  max-width: 250px;
  margin-top: 20px;
}

.login-page .logout-button:hover {
  background-color: var(--color4);
}

/* ----- Admin Page Button & Toggle Admin ----- */
.login-page .admin-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.login-page .admin-page-button,
.login-page .toggle-admin-button {
  background-color: var(--color4transparent);
  border: 2px solid var(--color4);
  color: var(--color1);
  font-size: 0.9em;
  padding: 5px 12px;
  max-width: fit-content;
}

.login-page .admin-page-button:hover,
.login-page .toggle-admin-button:hover {
  background-color: var(--color4);
}

/* ----- Welcome Panel ----- */
.login-page .welcome-container {
  text-align: left;
}

.login-page .user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.login-page .user-info p {
  color: var(--color5);
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 10px;
}

.login-page .user-info strong {
  color: var(--color1);
}

/* Email, Full Name, and Admin status highlighted */
.login-page .user-info .highlight {
  color: var(--color2);
  font-weight: bold;
}

/* ----- Tabs Container (Login / Create My Account) ----- */
.login-page .tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  gap: 10px;
}

/* Individual tab styling */
.login-page .tab {
  flex: 1;
  padding: 15px;
  text-align: center;
  background-color: var(--color4);
  color: var(--color5);
  border: 1px solid var(--color1);
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}

/* Add spacing between tabs */
.login-page .tab + .tab {
  margin-left: 5px;
}

.login-page .tab:not(.active) {
  background-color: var(--color4);
  opacity: 0.6;
}

.login-page .tab:hover {
  background-color: var(--color2);
}

.login-page .tab.active {
  background-color: var(--color1);
  color: var(--color3);
  opacity: 1;
  border-bottom: none;
}

/* ----- Tab Content ----- */
.login-page .tab-content {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

/* ----- INFO BLOCK (Why Create an Account) ----- */
.login-page .info-block {
  padding: 20px;
  background-color: var(--color4);
  border: 2px solid var(--color1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-width: 450px;
  width: 100%;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}

.login-page .info-block h2 {
  color: var(--color1);
  margin-bottom: 10px;
}

.login-page .info-block ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.login-page .info-block ul li {
  color: white;
  margin-bottom: 5px;
  font-weight: bold;
}

.login-page .info-block p {
  color: white;
}

/* ----- Message Overlays (Ko-fi Info) ----- */
.login-page .kofi-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.login-page .kofi-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--color4);
  color: var(--color1);
  padding: 20px;
  border: 2px solid var(--color1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  max-width: 400px;
  text-align: center;
}

.login-page .kofi-message-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}

.login-page .kofi-message-buttons button:hover {
  background-color: var(--color3);
}


/* ----- Form Inputs ----- */
input {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
  border: 2px solid var(--color4);
  border-radius: 5px;
  background-color: var(--color4transparent);
  color: var(--color1);
  font-size: 1em;
}

input:focus {
  outline: none;
  border-color: var(--color1);
}



/* ----- Responsive Adjustments ----- */
@media (max-width: 600px) {
  .login-button, 
  .auth-button,
  .logout-button {
    width: 100%;
  }
}
